import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Get from "../../Get";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ContenedoresCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_BROKERS = process.env.REACT_APP_URL_BROKERS;
  const URL_FORWARDERS = process.env.REACT_APP_URL_FORWARDERS;
  const URL_NAVIERAS = process.env.REACT_APP_URL_NAVIERAS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_CONTENEDORES = process.env.REACT_APP_URL_CONTENEDORES;
  const URL_ARTICULOS_CONTENEDOR = process.env.REACT_APP_URL_ARTICULOS_CONTENEDOR;
  const URL_SEND_MAIL = process.env.REACT_APP_URL_SEND_MAIL;
  const [t, i18n] = useTranslation("global");

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [brokers, setBrokers] = useState([]);
  const [forwarders, setForwarders] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState("");
  const [selectedForwarder, setSelectedForwarder] = useState("");
  const [navieras, setNavieras] = useState([]);
  const [selectedNaviera, setSelectedNaviera] = useState("");

  const [fecha, setFecha] = useState(hoy);
  const [numeroReferencia, setNumeroReferencia] = useState("");
  const [referenciaExtra, setReferenciaExtra] = useState("NA");
  const [flete, setFlete] = useState(0);
  const [incrementable, setIncrementable] = useState(0);
  const [puerto, setPuerto] = useState("");
  const [referencia, setReferencia] = useState("");
  const [fechaETD, setFechaETD] = useState("");
  const [fechaETA, setFechaETA] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [totalGeneral, setTotalGeneral] = useState(0);
  const [totalCantidad, setTotalCantidad] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 1,
      descripcion: "NA",
      proveedor: "NA",
      marca: "NA",
      PO: 0,
      CBMS: 0,
      precio: 1,
      total: 1,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(`${URL_CLIENTES}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allClientes = res.data;
          setClientes(allClientes);
        })
        .catch((err) => {
          console.log(err);
        });
        axios
        .get(`${URL_BROKERS}`, { 
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((res) => {
            const allBrokers = res.data;
            setBrokers(allBrokers);
          })
          .catch((err) => {
            console.log(err);
          });

        axios
        .get(`${URL_FORWARDERS}`, { 
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((res) => {
            const allForwarders = res.data;
            setForwarders(allForwarders);
          })
          .catch((err) => {
            console.log(err);
          });

          axios
          .get(`${URL_NAVIERAS}`, { 
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((res) => {
              const allNavieras = res.data;
              setNavieras(allNavieras);
            })
            .catch((err) => {
              console.log(err);
            });
  }, []);

  const saveOrdenCompra = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;

    try {
      await axios
        .post(
          URL_CONTENEDORES,
          {
            fecha,
            surtido:"No",
            status:"Abierto",
            clientes: selectedCliente,
            brokers: selectedBroker,
            forwarders: selectedForwarder,
            numeroReferencia,
            referenciaExtra,
            navieras: selectedNaviera,
            flete,
            incrementable,
            puerto,
            referencia,
            etd:fechaETD,
            etaEstimada: fechaETA,
            etaReal: fechaETA,
            bl:"No",
            pl:"No",
            totalGastos:0,
            fechaLlegada: "2000-01-01",
            fechaLiberacion: "2000-01-01",
            fechaEntregaEstimada: "2000-01-01",
            fechaEntregaReal: "2000-01-01",
            aduana: "No",
            totalGeneral,
            totalCantidad,
            totalSurtido:0,
            pendienteSurtir: totalCantidad,
            observaciones,
            montoServicio: 0,
            saldo: 0,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            axios
              .post(
                URL_ARTICULOS_CONTENEDOR,
                {
                  fecha,
                  contenedores: data.data._id,
                  navieras: selectedNaviera,
                  clientes: selectedCliente,
                  brokers: selectedBroker,
                  articulos: a.articulos,
                  cantidad: parseFloat(a.cantidad),
                  pendienteSurtir: parseFloat(a.cantidad),
                  descripcion: a.descripcion,
                  proveedor: a.proveedor,
                  marca: a.marca,
                  PO: a.PO,
                  CBMS: a.CBMS,
                  cantidadSurtido: 0,
                  precio: parseFloat(a.precio),
                  total: parseFloat(a.total),
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  enviaMail(data.data.referencia)
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Contenedor",
                        detalle: `${selectedCliente} - ${totalGeneral}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
                console.log(error);
                setValidaBoton(true);
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    }
  };

  function enviaMail(refecrencia){

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL,
        {
          subject: `Contenedor-${refecrencia}`,
          email: process.env.REACT_APP_MAIL_ADMIN,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
          <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
       <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
       <tbody>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Se ha creado un nuevo contenedor con el numero ${referencia}.</tr>
       </tbody>
       <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
       <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
       </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
}
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 1,
        descripcion: "NA",
        proveedor: "NA",
        marca: "NA",
        PO: 0,
        CBMS: 0,
        precio: 1,
        total: 1,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

 

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  }



  return (
    <>
      <Header />
      <br />
      <br />
      {user.contenedoresCreate ? (
        <div className="card container col-12">
          <h3 align="center">{t("Nuevo")} {t("Contenedor")}</h3>
          <Form onSubmit={saveOrdenCompra}>
            {/* <Get /> */}
            <Row>
              <Col md={2}>
                <Label>{t("Fecha")}</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">{t("Cliente")}</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedCliente}
                  required
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                  }}
                >
                  <option value="0">{t("Selecciona")}</option>
                  {clientes
                    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                    .map((a) => {
                        return <option value={a._id}>{a.nombre_comercial}</option>;
                    })}
                </Input>
              </Col>
             <Col md={2}>
                <Label className="mr-sm-2">{t("Naviera")}</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedNaviera}
                  required
                  onChange={(e) => {
                    setSelectedNaviera(e.target.value);
                  }}
                >
                  <option value="0">{t("Selecciona")}</option>
                  {navieras
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                        return <option value={a._id}>{a.name}</option>
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>{t("Puerto")}</Label>
                <Input
                  type="text"
                  placeholder="Puerto"
                  value={puerto}
                  required
                  onChange={(e) => {
                    setPuerto(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Broker</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedBroker}
                  required
                  onChange={(e) => {
                    setSelectedBroker(e.target.value);
                  }}
                >
                  <option value="0">{t("Selecciona")}</option>
                  {brokers
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Forwarder</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedForwarder}
                  required
                  onChange={(e) => {
                    setSelectedForwarder(e.target.value);
                  }}
                >
                  <option value="0">{t("Selecciona")}</option>
                  {forwarders
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if(user.tipo == "Forwarder"){
                        if(a._id == user.forwarders){
                          return <option value={a._id}>{a.name}</option>;
                        }
                      }else{
                        return <option value={a._id}>{a.name}</option>
                      }
                    })}
                </Input>
              </Col>
              </Row>
              <Row>
              <Col md={2}>
                <Label>{t("Numero de Referencia")}</Label>
                <Input
                  type="text"
                  placeholder="NumeroReferencia"
                  value={numeroReferencia}
                  required
                  onChange={(e) => {
                    setNumeroReferencia(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>{t("Referencia Extra")}</Label>
                <Input
                  type="text"
                  placeholder="ReferenciaExtra"
                  value={referenciaExtra}
                  required
                  onChange={(e) => {
                    setReferenciaExtra(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
  <Label>{t("Contenedor")}</Label>
  <Input
    type="text"
    placeholder="Referencia"
    value={referencia}
    required
    onChange={(e) => {
      const valueWithoutSpaces = e.target.value.replace(/\s/g, ''); // Elimina todos los espacios
      setReferencia(valueWithoutSpaces);
    }}
  />
</Col>
              <Col md={2}>
                <Label>ETD</Label>
                <Input
                  type="date"
                  placeholder="Fecha ETD"
                  value={fechaETD}
                  required
                  onChange={(e) => {
                    setFechaETD(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>ETA</Label>
                <Input
                  type="date"
                  placeholder="Fecha ETA"
                  value={fechaETA}
                  required
                  onChange={(e) => {
                    setFechaETA(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>{t("Flete")}</Label>
                <Input
                  type="number"
                  placeholder="Flete"
                  value={flete}
                  required
                  onChange={(e) => {
                    setFlete(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
            <Col md={2}>
                <Label>{t("Incrementable")}</Label>
                <Input
                  type="select"
                  placeholder="Incrementable"
                  value={incrementable}
                  required
                  onChange={(e) => {
                    setIncrementable(e.target.value);
                  }}
                >
                 <option value="">{t("Selecciona")}</option>
                 <option value="Si">{t("Si")}</option>
                 <option value="No">{t("No")}</option>
                  
                </Input> 
              </Col>
            </Row>
            <Row>
             
              <Col md={10}>
                <h4 id="logoutBoton">
                  TOTAL {totalCantidad} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(totalGeneral)}
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">{t("Articulos")}</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">{t("Descripcion")}</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">{t("Proveedor")}</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">{t("Marca")}</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">{t("PO")}</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">{t("CBMS")}</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">{t("Cantidad")}</Label>
              </Col>
              {/* <Col md={1}>
                <Label className="mr-sm-2">{t("Precio")} USD</Label>
              </Col> */}
              {/* <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col> */}
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        handleChangeInput(inputField.id, event);
                      }}
                    >
                      <option value="0">{t("Selecciona")}</option>
                      {articulos
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.name}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="descripcion"
                      type="text"
                      placeholder="descripcion"
                      value={inputField.descripcion}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="proveedor"
                      type="text"
                      placeholder="proveedor"
                      value={inputField.proveedor}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="marca"
                      type="text"
                      placeholder="marca"
                      value={inputField.marca}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="PO"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="PO"
                      value={inputField.PO}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="CBMS"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="CBMS"
                      value={inputField.CBMS}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  {/* <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col> */}

                  {/* <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col> */}
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
            <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  {t("Observaciones")}
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <h4 id="logoutBoton">
                  TOTAL {totalCantidad} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(totalGeneral)}
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                {t("Guardar")}
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                {t("Guardar")}
              </Button>
            )}
            <Button
              href="/ListadoContenedores"
              className="btn btn-danger"
              id="botonListado"
            >
               {t("Regresar")}
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>{t("Progreso")}</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                {t("Este proceso puede tardar varios segundos.")}
                <br />
                {t("Por favor no cierre ni refresque su navegador.")}
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ContenedoresCreate;
