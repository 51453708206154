import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.css"
import './index.css';
import Routes from './components/Routes'
import {I18nextProvider} from "react-i18next"
import i18next from "i18next"

import global_es from "./translations/es/global.json"
import global_en from "./translations/en/global.json"

import 'leaflet/dist/leaflet.css';

i18next.init({
  interpolation:{ escapeValue:false },
  lng: localStorage.getItem("idioma"),
  resources:{
    es:{
      global: global_es,
    },
    en:{
      global: global_en,
    },
  }
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <Routes />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

