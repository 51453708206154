import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { v4 as uuidv4 } from "uuid";

function ReporteContenedores() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_CONTENEDORES = process.env.REACT_APP_URL_CONTENEDORES;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_ARTICULOS_CONTENEDOR =
    process.env.REACT_APP_URL_ARTICULOS_CONTENEDOR;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_BROKERS = process.env.REACT_APP_URL_BROKERS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [t, i18n] = useTranslation("global");
  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 91);

  let comp30 = new Date();
  comp30.setDate(comp30.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [brokers, setBrokers] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [totalCantidad, setTotalCantidad] = useState(0);
  const [totalGeneralEdit, setTotalGeneralEdit] = useState(0);
  const [selectedFacturado, setSelectedFacturado] = useState("");

  const [totalTotalServicio, setTotalTotalServicio] = useState(0);
  const [totalTotalGastos, setTotalTotalGastos] = useState(0);
  const [totalTotalPagos, setTotalTotalPagos] = useState(0);
  const [totalTotalSaldo, setTotalTotalSaldo] = useState(0);

  const [idContenedor, setIdContenedor] = useState();
  const [photo, setPhoto] = useState([]);
  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);


  const [gastos, setGastos] = useState([]);
  const [modalGastos, setModalGastos] = useState(false);
  const toggleGastos = () => setModalGastos(!modalGastos);

  // const [inputFields, setInputFields] = useState([]);
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      precio: 0,
      total: 0,
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    // Solicitud para obtener contenedores
    axios
      .get(
        `${URL_CONTENEDORES}FechasReporte/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allContenedores = response.data;
        
        // Solicitud para obtener surtidos
        axios
          .get(`${URL_SURTIDOS}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((responseSurtidos) => {
            let allSurtidos = responseSurtidos.data;

            console.log("hola", allSurtidos)
            
            // Mapeo de contenedores para incluir los datos de SURTIDOS
            let arrayTabla = allContenedores
              .sort((a, b) => (a.idContenedor < b.idContenedor ? 1 : -1))
              .map((a) => {
                // Encuentra el surtido relacionado con el contenedor actual
                let relatedSurtido = allSurtidos.find(surtido => surtido.contenedores[0]._id === a._id);
                
                return {
                  _id: a._id,
                  numero: a.idContenedor,
                  activo: a.is_active,
                  fecha: a.fecha,
                  observaciones: a.observaciones,
                  totalGeneral: a.totalGeneral,
                  naviera: a.navieras[0].name,
                  idNaviera: a.navieras[0]._id,
                  clientes: a.clientes[0].nombre_comercial,
                  idClientes: a.clientes[0]._id,
                  brokers: a.brokers[0].name,
                  idBrokers: a.brokers[0]._id,
                  forwarders: a.forwarders[0].name,
                  idForwarders: a.forwarders[0]._id,
                  totalCantidad: a.totalCantidad,
                  totalSurtido: a.totalSurtido,
                  pendienteSurtir: a.pendienteSurtir,
                  numeroReferencia: a.numeroReferencia,
                  referenciaExtra: a.referenciaExtra,
                  flete: a.flete,
                  referencia: a.referencia,
                  etd: a.etd,
                  etaEstimada: a.etaEstimada,
                  etaReal: a.etaReal,
                  bl: a.bl,
                  pl: a.pl,
                  totalGastos: a.totalGastos,
                  fechaLlegada: a.fechaLlegada,
                  fechaLiberacion: a.fechaLiberacion,
                  fechaEntregaEstimada: a.fechaEntregaEstimada,
                  fechaEntregaReal: a.fechaEntregaReal,
                  aduana: a.aduana,
                  montoServicio: a.montoServicio,
                  saldo: a.saldo,
                  pagos: a.montoServicio - a.saldo,
                  fechaPago: a.fechaPago,
                  facturado: a.facturado,
                  facturadoFacturas: a.facturado === "Si" ? a.facturado + " " + a.cantidadFacturas : a.facturado,
                  fechaPagoGasto: a.fechaPagoGasto,
                  surtidoId: relatedSurtido ? relatedSurtido._id : "", // Agrega el _id del surtido relacionado
                };
              })
              .filter(function (el) {
                return el != null;
              });
  
            let dataFinal = Object.values(arrayTabla);
  
            setComments(dataFinal);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  
    // Solicitud para obtener clientes
    axios
      .get(`${URL_CLIENTES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  
    // Solicitud para obtener brokers
    axios
      .get(`${URL_BROKERS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBrokers = response.data;
        setBrokers(allBrokers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);
  

// useMemo(() => {
//     axios
//       .get(
//         `${URL_CONTENEDORES}FechasReporte/${selectedFechaInicio}/${selectedFechaFin}`,
//         {
//           headers: {
//             Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//           },
//         }
//       )
//       .then((response) => {
//         let allContenedores = response.data;
//         // Array para pagination
//         let arrayTabla = allContenedores
//           .sort((a, b) => (a.idContenedor < b.idContenedor ? 1 : -1))
//           .map((a) => {
//             return {
//               _id: a._id,
//               numero: a.idContenedor,
//               activo: a.is_active,
//               fecha: a.fecha,
//               observaciones: a.observaciones,
//               totalGeneral: a.totalGeneral,
//               naviera: a.naviera,
//               clientes: a.clientes[0].nombre_comercial,
//               idClientes: a.clientes[0]._id,
//               brokers: a.brokers[0].name,
//               idBrokers: a.brokers[0]._id,
//               totalCantidad: a.totalCantidad,
//               totalSurtido: a.totalSurtido,
//               pendienteSurtir: a.pendienteSurtir,
//               numeroReferencia: a.numeroReferencia,
//               referencia: a.referencia,
//               etd: a.etd,
//               etaEstimada: a.etaEstimada,
//               etaReal: a.etaReal,
//               bl: a.bl,
//               pl: a.pl,
//               totalGastos: a.totalGastos,
//               fechaLlegada: a.fechaLlegada,
//               fechaLiberacion: a.fechaLiberacion,
//               fechaEntregaEstimada: a.fechaEntregaEstimada,
//               fechaEntregaReal: a.fechaEntregaReal,
//               aduana: a.aduana,
//               montoServicio: a.montoServicio,
//               saldo: a.saldo,
//               pagos: a.montoServicio - a.saldo,
//               fechaPago: a.fechaPago,
//               facturado: a.facturado,
//               facturadoFacturas: a.facturado === "Si" ? a.facturado + " " + a.cantidadFacturas : a.facturado, // Agregar cantidadFacturas solo si facturado es "Si"
//               fechaPagoGasto: a.fechaPagoGasto,
//             };
//           })
//           .filter(function (el) {
//             return el != null;
//           });

//         let dataFinal = Object.values(arrayTabla);

//         setComments(dataFinal);
//         //
//       })
//       .catch((err) => {
//         console.log(err);
//       });

//     axios
//       .get(`${URL_CLIENTES}`, {
//         headers: {
//           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//         },
//       })
//       .then((response) => {
//         let allClientes = response.data;
//         setClientes(allClientes);
//       })
//       .catch((err) => {
//         console.log(err);
//       });

//     axios
//       .get(`${URL_BROKERS}`, {
//         headers: {
//           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//         },
//       })
//       .then((response) => {
//         let allBrokers = response.data;
//         setBrokers(allBrokers);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, [selectedFechaInicio, selectedFechaFin]);


// useMemo(() => {
//   axios
//     .get(
//       `${URL_SURTIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
//       {
//         headers: {
//           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//         },
//       }
//     )
//     .then((response) => {
//       let allContenedores = response.data;
//       // Array para pagination
//       let arrayTabla = allContenedores
//         .sort((a, b) => (a.idContenedor < b.idContenedor ? 1 : -1))
//         .map((a) => {
//           return {
//             idSurtido: a._id,
//             _id: a.contenedores[0]._id,
//             numero: a.contenedores[0].idContenedor,
//             activo: a.contenedores[0].is_active,
//             fecha: a.contenedores[0].fecha,
//             observaciones: a.contenedores[0].observaciones,
//             totalGeneral: a.contenedores[0].totalGeneral,
//             naviera: a.contenedores[0].naviera,
//             clientes: a.clientes[0].nombre_comercial,
//             idClientes: a.clientes[0]._id,
//             brokers: a.contenedores[0].brokers[0].name,
//             idBrokers: a.contenedores[0].brokers[0]._id,
//             totalCantidad: a.contenedores[0].totalCantidad,
//             totalSurtido: a.contenedores[0].totalSurtido,
//             pendienteSurtir: a.contenedores[0].pendienteSurtir,
//             numeroReferencia: a.contenedores[0].numeroReferencia,
//             referencia: a.contenedores[0].referencia,
//             etd: a.contenedores[0].etd,
//             etaEstimada: a.contenedores[0].etaEstimada,
//             etaReal: a.contenedores[0].etaReal,
//             bl: a.contenedores[0].bl,
//             pl: a.contenedores[0].pl,
//             totalGastos: a.contenedores[0].totalGastos,
//             fechaLlegada: a.contenedores[0].fechaLlegada,
//             fechaLiberacion: a.contenedores[0].fechaLiberacion,
//             fechaEntregaEstimada: a.contenedores[0].fechaEntregaEstimada,
//             fechaEntregaReal: a.contenedores[0].fechaEntregaReal,
//             aduana: a.contenedores[0].aduana,
//             montoServicio: a.contenedores[0].montoServicio,
//             saldo: a.contenedores[0].saldo,
//             pagos: a.contenedores[0].montoServicio - a.saldo,
//             fechaPago: a.contenedores[0].fechaPago,
//             facturado: a.contenedores[0].facturado,
//             facturadoFacturas: a.contenedores[0].facturado === "Si" ? a.facturado + " " + a.cantidadFacturas : a.contenedores[0].facturado, // Agregar cantidadFacturas solo si facturado es "Si"
//             fechaPagoGasto: a.contenedores[0].fechaPagoGasto,
//           };
//         })
//         .filter(function (el) {
//           return el != null;
//         });

//       let dataFinal = Object.values(arrayTabla);

//       setComments(dataFinal);
//       //
//     })
//     .catch((err) => {
//       console.log(err);
//     });

//   axios
//     .get(`${URL_CLIENTES}`, {
//       headers: {
//         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//       },
//     })
//     .then((response) => {
//       let allClientes = response.data;
//       setClientes(allClientes);
//     })
//     .catch((err) => {
//       console.log(err);
//     });

//   axios
//     .get(`${URL_BROKERS}`, {
//       headers: {
//         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//       },
//     })
//     .then((response) => {
//       let allBrokers = response.data;
//       setBrokers(allBrokers);
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// }, [selectedFechaInicio, selectedFechaFin]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedBroker == 0 || selectedBroker == a.idBrokers)
      ) {
        return [
          a.numero,
          a.fecha,
          a.numeroReferencia,
          a.referenciaExtra,
          a.referencia,
          a.clientes,
          // a.naviera,
          a.forwarders,
          a.brokers,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
          a.etd,
          a.etaEstimada,
          a.etaReal,
          a.fechaLiberacion,
          a.fechaEntregaReal,
          "$" + new Intl.NumberFormat("en-US").format(a.flete),
          "$" + new Intl.NumberFormat("en-US").format(a.montoServicio),
          "$" + new Intl.NumberFormat("en-US").format(a.totalGastos),
          // "$" +
          //   new Intl.NumberFormat("en-US").format(
          //     a.montoServicio - a.totalGastos
          //   ),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 65, 28);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "No. Ref",
          "Referencia",
          "Cliente",
          // "Naviera",
          "Forwarder",
          "Brokers",
          "Cantidad",
          "ETD",
          "ETA Est",
          "ETA Real",
          "Fecha Lib",
          "Fecha Entrega",
          "Flete",
          "Monto Servicio",
          "Gastos",
          // "Utilidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Contenedores.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedBroker == 0 || selectedBroker == a.idBrokers)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          NoRef: a.numeroReferencia,
          RefExtra: a.referenciaExtra,
          Referencia: a.referencia,
          Cliente: a.clientes,
          // Naviera: a.naviera,
          Forwarder: a.forwarders,
          Brocker: a.brokers,
          Cantidad: new Intl.NumberFormat("en-US").format(a.totalCantidad),
          ETD: a.etd,
          ETAEst: a.etaEstimada,
          ETAReal: a.etaReal,
          FechaLib: a.fechaLiberacion,
          FechaEntrega: a.fechaEntregaReal,
          Flete: "$" + new Intl.NumberFormat("en-US").format(a.flete),
          MontoServicio:
            "$" + new Intl.NumberFormat("en-US").format(a.montoServicio),
          Gasto: "$" + new Intl.NumberFormat("en-US").format(a.totalGastos),
          // Utilidad:
          //   "$" +
          //   new Intl.NumberFormat("en-US").format(
          //     a.montoServicio - a.totalGastos
          //   ),
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ReporteContenedores";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ReporteContenedores",
        sheetFilter: [
          "Numero",
          "Fecha",
          "NoRef",
          "RefExtra",
          "Referencia",
          "Cliente",
          // "Naviera",
          "Forwarder",
          "Brocker",
          "Cantidad",
          "ETD",
          "ETAEst",
          "ETAReal",
          "FechaLib",
          "FechaEntrega",
          "Flete",
          "MontoServicio",
          "Gasto",
          // "Utilidad",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "NoRef",
          "RefExtra",
          "Referencia",
          "Cliente",
          // "Naviera",
          "Forwarder",
          "Brocker",
          "Cantidad",
          "ETD",
          "ETAEst",
          "ETAReal",
          "FechaLib",
          "FechaEntrega",
          "Flete",
          "MontoServicio",
          "Gasto",
          // "Utilidad",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedBroker == 0 || selectedBroker == a.idBrokers)
      ) {
        return [
          a.numero,
          a.fecha,
          a.numeroReferencia,
          a.referencia,
          a.clientes,
          // a.naviera,
          a.forwarders,
          a.brokers,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
          a.etd,
          a.etaEstimada,
          a.etaReal,
          a.fechaLiberacion,
          a.fechaEntregaReal,
          "$" + new Intl.NumberFormat("en-US").format(a.flete),
          "$" + new Intl.NumberFormat("en-US").format(a.montoServicio),
          "$" + new Intl.NumberFormat("en-US").format(a.totalGastos),
          // "$" +
          //   new Intl.NumberFormat("en-US").format(
          //     a.montoServicio - a.totalGastos
          //   ),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 65, 28);
    doc.text(`Listado Contenedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "No. Ref",
          "Referencia",
          "Cliente",
          // "Naviera",
          "Forwarder",
          "Brokers",
          "Cantidad",
          "ETD",
          "ETA Est",
          "ETA Real",
          "Fecha Lib",
          "Fecha Entrega",
          "Flete",
          "Monto Servicio",
          "Gastos",
          // "Utilidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Contenedores",
          email: mailTo,
          fileName: "ReporteContenedores.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Contenedores.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    clientes,
    brokers,
    totalCantidad,
    totalGeneral,
    observaciones,
    numero,
    naviera,
    idPDFOC
  ) {
    await axios
      .get(`${URL_ARTICULOS_CONTENEDOR}/contenedor/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosContenedor = response.data;
        let arrayTabla = allArticulosContenedor
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].name,
              descripcion: a.descripcion,
              cantidad: a.cantidad,
              precio: a.precio,
              total: a.total,
              pendienteSurtir: a.pendienteSurtir,
              cantidadSurtido: a.cantidadSurtido,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let cant = inputFields.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidad(TC);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);

        const data = dataFinal.map((a) => {
          return [
            a.articulos,
            a.descripcion,
            new Intl.NumberFormat("en-US").format(a.cantidad),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 20, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 45, 15);
        doc.text(`Contenedor # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Naviera ${naviera}`, 20, 30);
        doc.text(
          `Piezas ${new Intl.NumberFormat("en-US").format(totalCantidad)}`,
          20,
          35
        );
        doc.autoTable({
          head: [["Articulo", "Descripcion", "Cantidad"]],
          body: dataPDFLimpia,
          startY: 40,
          foot: [
            // [
            //   "",
            //   "",
            //   "",
            //   "Total",
            //   "$" + new Intl.NumberFormat("en-US").format(totalGeneral),
            // ],
          ],
          showFoot: "lastPage",
        });
        // doc.save(`Contenedor-${numero}.pdf`);

        window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    // { name: `${t("Numero")}`, field: "numero", sortable: true },
    { name: `${t("Fecha")}`, field: "fecha", sortable: true },
    { name: `${t("No. Referencia")}`, field: "numeroReferencia", sortable: true, },
    { name: `${t("Ref Extra")}`, field: "referenciaExtra", sortable: true, },
    { name: `${t("Contenedor")}`, field: "referencia", sortable: true },
    { name: `${t("Cliente")}`, field: "clientes", sortable: true },
    // { name: `${t("Naviera")}`, field: "naviera", sortable: true },
    { name: `${t("Forwarder")}`, field: "Forwarder", sortable: true },
    { name: `${t("ETD")}`, field: "etd", sortable: true },
    { name: `${t("ETA Puerto")}`, field: "etaReal", sortable: true },
    // { name: `${t("ETA Real")}`, field: "etaReal", sortable: true },
    { name: `${t("Broker")}`, field: "brokers", sortable: true },
    { name: `${t("Cantidad")}`, field: "totalCantidad", sortable: true },
    // { name: `${t("Liberacion")}`, field: "fechaLiberacion", sortable: true },
    { name: `${t("Entrega")}`, field: "fechaEntregaReal", sortable: true },
    { name: `${t("Flete")}`, field: "flete", sortable: true },
    { name: `${t("Costos Imp")}`, field: "totalGastos", sortable: true },
    { name: `${t("Facturado")}`, field: "facturadoFacturas", sortable: true },
    { name: `${t("Monto Facturado")}`, field: "montoServicio", sortable: true },
    { name: `${t("Pagos")}`, field: "abonos", sortable: true },
    { name: `${t("Fecha Pago")}`, field: "fechaPago", sortable: true },
    { name: `${t("Saldo")}`, field: "saldo", sortable: true },
    // { name: `${t("Utilidad")}`, field: "utilidad", sortable: false },
    { name: `${t("Det.Piezas")}`, field: "pdf", sortable: false },
    // { name: `${t("Det. Abonos")}`, field: "abonos", sortable: false },
    // { name: `${t("Det. Costos")}`, field: "costos", sortable: false },
    { name: `${t("Archivos")}`, field: "archivos", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.numero.toString().includes(search) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.numeroReferencia
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.referenciaExtra.toLowerCase().includes(search.toLowerCase()) ||
          comment.referencia.toLowerCase().includes(search.toLowerCase()) ||
          // comment.naviera.toLowerCase().includes(search.toLowerCase()) ||
          comment.forwarders.toLowerCase().includes(search.toLowerCase()) ||
          comment.totalCantidad.toString().includes(search) ||
          comment.brokers.toLowerCase().includes(search.toLowerCase()) ||
          comment.facturadoFacturas.toLowerCase().includes(search.toLowerCase()) ||
          comment.totalGastos.toString().includes(search) ||
          comment.montoServicio.toString().includes(search) ||
          comment.flete.toString().includes(search) ||
          comment.saldo.toString().includes(search)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (selectedFacturado) {
      computedComments = computedComments.filter((e) =>
        e.facturado.includes(selectedFacturado)
      );
    }
    let car = computedComments.map((c) => parseFloat(c.montoServicio));
    let TCar = car.reduce((t, cargos, index) => t + cargos, 0);
    setTotalTotalServicio(TCar);

    let abo = computedComments.map((c) => parseFloat(c.totalGastos));
    let TAbo = abo.reduce((t, abonos, index) => t + abonos, 0);
    setTotalTotalGastos(TAbo);

    let pag = computedComments.map((c) => parseFloat(c.pagos));
    let TPag = pag.reduce((t, pagos, index) => t + pagos, 0);
    setTotalTotalPagos(TPag);

    let sal = computedComments.map((c) => parseFloat(c.saldo));
    let TSal = sal.reduce((t, saldo, index) => t + saldo, 0);
    setTotalTotalSaldo(TSal);

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "totalCantidad" &&
      sorting.field != "flete" &&
      sorting.field != "totalGastos" &&
      sorting.field != "montoServicio" &&
      sorting.field != "saldo" &&
      sorting.field != "abonos" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field != "flete" ||
        sorting.field != "totalGastos" ||
        sorting.field != "montoServicio" ||
        sorting.field != "saldo" ||
        sorting.field != "abonos" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field != "flete" ||
        sorting.field != "totalGastos" ||
        sorting.field != "montoServicio" ||
        sorting.field != "saldo" ||
        sorting.field != "abonos" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedFacturado,
  ]);
  function jalaFoto(idCont, idSurt) {
    console.log("hola2", idCont, idSurt);
    
    const URL_GET_MEDIA_CONT = `${process.env.REACT_APP_URL_FINDMEDIABYTAG}/${idCont}`;
    const token = localStorage.getItem("app_token");
  
    console.log("URL_GET_MEDIA_CONT:", URL_GET_MEDIA_CONT);
    console.log("Token:", token);
    
    const requests = [
      axios.get(URL_GET_MEDIA_CONT, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ];
  
    if (idSurt) {
      const URL_GET_MEDIA_SURT = `${process.env.REACT_APP_URL_FINDMEDIABYTAG}/${idSurt}`;
      console.log("URL_GET_MEDIA_SURT:", URL_GET_MEDIA_SURT);
      requests.push(
        axios.get(URL_GET_MEDIA_SURT, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );
    }
  
    Promise.all(requests)
      .then((responses) => {
        const photosCont = responses[0].data.resources;
        let photosSurt = [];
  
        if (idSurt && responses[1]) {
          photosSurt = responses[1].data.resources;
          console.log("Photos Surt:", photosSurt);
        }
  
        console.log("Photos Cont:", photosCont);
        const allPhotos = [...photosCont, ...photosSurt];
        console.log("All Photos:", allPhotos);
  
        setPhoto(allPhotos);
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  
    // setIdSurtido(idSurt);
    toggleFoto();
  }
  
  

  function jalaGastos(idCont) {
    const URL_GASTOS_CONTENEDOR = `${process.env.REACT_APP_URL_GASTOS_CONTENEDOR}Contenedor/${idCont}`;
    axios
      .get(URL_GASTOS_CONTENEDOR, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGastos = response.data;
        setGastos(allGastos);
        toggleGastos();
      })
      .catch((err) => console.log(err));
  }

  // function jalaAbonos(idCont) {
  //   const URL_ABONOS = `${process.env.REACT_APP_URL_ABONOS}Contenedor/${idCont}`;
  //   axios
  //     .get(URL_ABONOS, {
  //       headers: {
  //         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //       },
  //     })
  //     .then((response) => {
  //       let allGastos = response.data;
  //       setGastos(allGastos);
  //       toggleGastos();
  //     })
  //     .catch((err) => console.log(err));
  // }

let totalGastos = 0
  return (
    <>
      <Header />
      <br />
      <br />
      {user.contenedoresReporte ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.contenedoresReporte ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ContenedoresCreate"
                >
                  {t("Nuevo")}
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  {t("Nuevo")}
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuContenedores"
                className="btn btn-danger"
                id="botonListado"
              >
                {t("Regresar")}
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>{t("Enviar Listado Contenedores")}</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                {t("Enviar")}
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">{t("Reporte Contenedores")}</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Inicio")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">{t("Fecha Fin")}</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>{t("Facturado")}</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedFacturado}
                onChange={(e) => {
                  setSelectedFacturado(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">{t("Selecciona")}</option>
                <option value="Si">{t("Si")}</option>
                <option value="No">{t("No")}</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      {/* <td>{a.numero}</td> */}
                      <td>{a.fecha}</td>
                      <td>{a.numeroReferencia}</td>
                      <td>{a.referenciaExtra}</td>
                      <td>{a.referencia}</td>
                      <td>{a.clientes}</td>
                      {/* <td>{a.naviera}</td> */}
                      <td>{a.forwarders}</td>
                      <td>{a.etd}</td>
                      {/* <td>{a.etaEstimada}</td> */}
                      <td>{a.etaReal}</td>
                      <td>{a.brokers}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.totalCantidad)}
                      </td>
                      {/* <td>{a.fechaLiberacion}</td> */}
                      {/* <td>{a.fechaLlegada}</td>
                      <td>{a.fechaEntregaEstimada}</td> */}
                      <td>{a.fechaEntregaReal}</td>

                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.flete
                          )}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(a.totalGastos)}
                      </td>
                      <td>{a.facturadoFacturas}</td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.montoServicio
                          )}
                      </td>

                      
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.pagos
                          )}
                      </td>
                      <td>{a.fechaPago}</td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.saldo
                          )}
                      </td>
                     {/* <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.montoServicio - a.totalGastos
                          )}
                        </td> */}
                      <td>

                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.clientes,
                                  a.brokers,
                                  a.totalCantidad,
                                  a.totalGeneral,
                                  a.observaciones,
                                  a.numero,
                                  a.naviera,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                      </td>
                      {/* <td>
                      <Button
                                color="success"
                                id="Editar"
                                size="sm"
                                // onClick={(e) => jalaAbonos(a._id)}
                              >
                                <i class="fas fa-dollar-sign"></i>
                              </Button>
                              </td> */}
                              {/* <td>

                            <Button
                                color="success"
                                id="Editar"
                                size="sm"
                                onClick={(e) => jalaGastos(a._id)}
                              >
                                <i class="fas fa-dollar-sign"></i>
                              </Button> 
                              </td>*/}
<td>
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) => jalaFoto(a._id, a.surtidoId)}
                              >
                                <i class="fas fa-camera"></i>
                              </Button>
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="right">
                  TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalTotalGastos)}
                </td>
                <td></td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalTotalServicio)}
                </td>
                
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalTotalPagos)}
                </td>
                <td></td>
                
                
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalTotalSaldo)}
                </td>
                {/* <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalTotalServicio - totalTotalGastos)}
                </td> */}
                <td></td>
                {/* <td></td> */}
                {/* <td></td> */}
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xxl" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>Fotos</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            {photo.length > 0 ? (
              photo.map((item) => {
                if (item.format === "pdf") {
                  return (
                    <h6 key={item.id} style={{ textAlign: "center" }}>
                      <embed
                        src={item.secure_url}
                        type="application/pdf"
                        width="500"
                        height="600"
                      />
                    </h6>
                  );
                } else {
                  return (
                    <h6 key={item.id} style={{ textAlign: "center" }}>
                      <img
                        src={item.secure_url}
                        alt="Uploaded Image"
                        width="250"
                        height="auto"
                      />
                    </h6>
                  );
                }
                // else {
                //   return null; // Si el tipo no es ni imagen ni PDF, puedes manejarlo según tus necesidades
                // }
              })
            ) : (
              <h4>No se ha subido una Foto o PDF....</h4>
            )}
          </Row>
        </ModalBody>
      </Modal>


      <Modal size="xxl" isOpen={modalGastos} toggle={toggleGastos}>
        <ModalHeader toggle={toggleGastos}>
          <h4>Gastos</h4>
        </ModalHeader>
        <ModalBody>
          
        <div className="card container">
          <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                      <tr align="center">
                        <th className="tituloTabla" align="left">
                          Fecha
                        </th>
                        <th className="tituloTabla">
                          Descripcion
                        </th>
                        <th className="tituloTabla">
                          {t("Monto")}
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      {gastos
                      .map((c) => {
                        totalGastos = totalGastos + c.total
                        return (
                          <tr>
                            <td align="center">{c.fecha}</td>
                           <td align="center">{c.conceptosGastos[0].name}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(c.total)}</td>
                         </tr>
                        )
                      })}

                      <tr>
                        <td></td>
                        <td className="negrita" align="center">{t("Total")}</td>
                        <td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(totalGastos)}</td>
                      </tr>

                    </tbody>
                  </Table>
          </div>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ReporteContenedores;
